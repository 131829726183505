/* You can add global styles to this file, and also import other style files */

/*For the material dropdown to work*/
@import '~@angular/material/prebuilt-themes/indigo-pink.css';


app-root {
    display: flex;
    background-color: #FAFBFE; /*#ff1124;*/
    justify-content: center;
    height: 100vh;
}


app-gpus-page {
    width: 100%;
    overflow-x: hidden;
}

/* -webkit-font-smoothing    only works on Mac OS X/macOS*/
/*  https://caniuse.com/font-smooth  */
body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.base-page {
    height: 100vh;
    width: 100vw;
    background-size: 100%;
    background-color: #FAFBFE;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.badge-npp {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;

    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;

    user-select: none;

    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.npp-status-status {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
}

.npp-status-approved {
    color: #fff;
    background-color: #0acf97;
    border-color: #0acf97;
  /*box-shadow: 0px 2px 6px rgba(10, 207, 151, 0.5);
    border-radius: 4px;*/
}

.npp-status-submitted {
    color: #fff;
    background-color: #39b0d2;
    border-color: #39b0d2;
    /*box-shadow: 0px 2px 6px rgba(10, 207, 151, 0.5);
    border-radius: 4px;*/
}

.npp-status-in-progress {
    color: #fff;
    background-color: #727cf5;
    border-color: #727cf5;
    /*box-shadow: 0px 2px 6px rgba(10, 207, 151, 0.5);
    border-radius: 4px;*/
}

.npp-status-awaiting {
    color: #fff;
    background-color: #ffbc01;
    border-color: #ffbc01;
    /*box-shadow: 0px 2px 6px rgba(10, 207, 151, 0.5);
    border-radius: 4px;*/
}

.npp-status-issues-found {
    color: #fff;
    background-color: #ff0000;
    border-color: #ff0000;
    /*box-shadow: 0px 2px 6px rgba(10, 207, 151, 0.5);
    border-radius: 4px;*/
}

.npp-status-change-requested {
    color: #fff;
    background-color: #ff00bf;
    border-color: #ff00bf;
}


/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
